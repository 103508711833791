import React, { useState } from "react";
import { questions } from "./api";
import "./Faq.css";
import MyAccordion from "./MyAccordion";
const Faq = () => {
  const [data, setData] = useState(questions);
  return (
    <div className="faq" id="faq">
      <div className="container p-3">
        <div className="title text-center mt-5">FAQ </div>
        <div className="col-10 col-lg-10 mx-auto">
          <div className="dark-part mt-5 mb-5">
            <div className="accordion mt-5" id="accordionExample">
              {data.map((curElem) => {
                return <MyAccordion key={curElem.id} element={curElem} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
