export const questions = [
  // {
  //   id: 1,
  //   question: "Opensea?",
  //   answer: "https://opensea.io/collection/loserapeclub",
  // },
  {
    id: 2,
    question: "Blockchain?",
    answer: "Ethereum.",
  },
  {
    id: 3,
    question: "What can you do with your Loser Ape Club?",
    answer:
      "Anything you want. Each holder is given ownership and commercial usage rights of their NFT.",
  },
  {
    id: 4,
    question: "When is the official launch?",
    answer: "Official date will be announced through our socials.",
  },
  {
    id: 5,
    question: "How mint a Loser Ape Club?",
    answer:
      "You can mint a LAC as soon as the public sale begins directly on our website.",
  },
  // {
  //   id: 6,
  //   question: "What is the mint price of a Loser Ape Club NFT?",
  //   answer:
  //     "Whitelist: free & 0.01 ETH - Public Sale: 0.02 & 0.03 ETH. The exact batches will be announced through our social channels.",
  // },
  // {
  //   id: 6,
  //   question: "Why mint in $LOOKS beside $ETH?",
  //   answer:
  //     "The team is bullish on the LooksRare platform. The amount of $LOOKS that will be collected during the public sale will be entirely staked on the LooksRare platform and the revenues will be allocated to the treasury.",
  // },
  // {
  //   id: 7,
  //   question: "hy should I mint a Fox?",
  //   answer:
  //     "We want to create an active and productive environment where each member can contribute if he/she wants, along with creating value for the holders with the holders. Different experitizes are going to be necessary for the success of the DAO and will be rewarded by the DAO itself. Beside this, the art is very cool!",
  // },
  // {
  //   id: 8,
  //   question: "How do I contact the team?",
  //   answer:
  //     "Join our Discord, and feel free to ask any questions you have. All essential information will be announced on Discord.",
  // },
];
