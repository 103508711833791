import React from "react";
import "./Plans.css";
import table2 from "../Image/Table/table2.png";
const Plans = () => {
  return (
    <div className="plans">
      <div className="container">
        <a name="plans"></a>
        <div className="col-10 mx-auto p-3">
          <div className="title padding">COMPETE TO EARN $FOOT</div>
          <div className="description mb-5">
            Finally you can compete with players from all over the world, prove
            you are the greatest football manager of the season and win the
            championship! Sign up, set up your best line up and start your
            career! Are you ready for the challenge?
          </div>
          <div className="table2-shadow mb-5">
            <img src={table2} alt="" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
