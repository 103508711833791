import React from "react";
import "./Rules.css";
const Rules = () => {
  return (
    <div className="rules">
      <div className="container">
        <a name="rules"></a>
        <div className="col-10 mx-auto p-3">
          <div className="title padding">HOW TO PLAY</div>
          <div className="description mb-5">
            Buy your pass and start to play. You'll receive an amount of 250
            $FOOT with which you could buy 25 players according to their
            respective roles: 3 goalkeepers, 8 defenders, 8 midfielders and 6
            forwards. No market auctions, every NFTFootballClub manager could
            buy as many players as he wants as long as he doesn't spend more
            than his available $FOOT. The tokens not spent in one market session
            will be available for the next session. Each week you must field the
            11 starting players and 10 reserves. If one of your selected
            starting players misses the game, he will be replaced by one of your
            selected reserves, in the order you have chosen. On the official
            website you will find all the expected line-ups for all the matches
            of the leagues that feature in the game and all the statistics you
            need to make the best choices. The team scores will be determined by
            the scoresheets (with player ratings, disciplinary measures and
            goalscorers) published by the national sports daily press which the
            members of NFTFootballClub will have chosen in a poll before the
            tournament starts. We will propose 3 major sports daily presses for
            each league and you will choose which one will become the official
            oracle of the game through a snapshot vote. Each team will get a
            total score given by the sum of their players' scores. The total of
            the points collected, added weekly, will define the rankings: the
            general ranking, the monthly ranking and the daily ranking. There
            are no games between the teams, each team will assume the ranking
            position given by the total points gained each week. Quite simply,
            the team that gets more points will win the competitions.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Rules;
