import React from "react";
import Content from "./Concept";
import "./Concept.css";
import table1 from "../Image/Table/table1.png";
const Concept = () => {
  return (
    <>
      <div className="concept">
        <div className="container">
          <a name="concept"></a>
          <div className="col-10 mx-auto p-3">
            <div className="padding">
              <div className="title">BECOME THE BEST MANAGER</div>
            </div>
            <div className="description mb-5">
              Create your own team by choosing players from Europe's top
              leagues, line them up on the football pitch and compete to win
              weekly rankings and get the ultimate glory by becoming the NFT
              Football Club 2022 champion. 4 billion football fans in the world,
              only 20,000 entry spots available, 250 $FOOT tokens to build your
              team and over $3 million prize pool.
              {/* <br /> */}
            </div>
            <div className="table1-shadow mb-5">
              <img src={table1} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Concept;
